// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ENJewXhWc", "r2DNyuDzP"];

const serializationHash = "framer-stGYX"

const variantClassNames = {ENJewXhWc: "framer-v-11u0pto", r2DNyuDzP: "framer-v-1py0d4y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"16px": "r2DNyuDzP", "32px": "ENJewXhWc"}

const getProps = ({height, icon, id, width, ...props}) => { return {...props, JvL7R8BWe: icon ?? props.JvL7R8BWe, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ENJewXhWc"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, JvL7R8BWe, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "ENJewXhWc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), sizes: componentViewport?.width || "100vw", ...toResponsiveImage(JvL7R8BWe), ...{ positionX: "center", positionY: "center" }}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11u0pto", className, classNames)} data-framer-name={"32px"} layoutDependency={layoutDependency} layoutId={"ENJewXhWc"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({r2DNyuDzP: {"data-framer-name": "16px"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-stGYX.framer-35p48w, .framer-stGYX .framer-35p48w { display: block; }", ".framer-stGYX.framer-11u0pto { height: 32px; overflow: visible; position: relative; width: 32px; }", ".framer-stGYX.framer-v-1py0d4y.framer-11u0pto { height: 16px; width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"r2DNyuDzP":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"JvL7R8BWe":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfaN27UAr2: React.ComponentType<Props> = withCSS(Component, css, "framer-stGYX") as typeof Component;
export default FramerfaN27UAr2;

FramerfaN27UAr2.displayName = "Platform icon";

FramerfaN27UAr2.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerfaN27UAr2, {variant: {options: ["ENJewXhWc", "r2DNyuDzP"], optionTitles: ["32px", "16px"], title: "Variant", type: ControlType.Enum}, JvL7R8BWe: {title: "Icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerfaN27UAr2, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})